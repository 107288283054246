.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.search-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input-wrapper {
  position: relative;
  width: 250px;
}

.search-input-wrapper input[type="text"] {
  width: 300px;
  height: 48px;
  padding-left: 40px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: #dfe7ee;
}

.search-input-wrapper input[type="text"]:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.search-input-wrapper .search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}
