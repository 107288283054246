.sp-availability-page {
  margin-top: 40px;
  width: 100%;
}

.sp-tab-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  width: 95%;
}

.sp-tab.spactive {
  border-bottom: 3.5px solid #4695b8;
  border-radius: 6px;
  font-weight: 700;
}

.sp-tab {
  margin: 0 50px;
  width: 50%;
  text-align: center;
}

.sp-card-container {
  margin: 0px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 20px 0px;
}

.sp-card {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.sp-card-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.sp-card-id {
  color: #333;
}

.sp-card-name {
  font-size: 20px;
}

.sp-status {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 13px;
}

.sp-status.available {
  background-color: #e0f7fa;
  color: #06ad9d;
}

.sp-status.unavailable {
  background-color: #ffebee;
  color: #ff0000;
}

.sp-card-subhead {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  gap: 10px;
  position: relative;
}

.sp-card-subhead-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 2px solid #000;
  color: #333;
  font-weight: 500;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  position: relative;
}

.sp-card-subhead-detail span {
  position: absolute;
  top: -11px;
  left: 10px;
  background-color: #fff;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.sp-card-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.9em;
  color: #666;
}

.sp-card-detail img {
  width: 20px;
  padding-right: 10px;
}

.sp-card-add-detail {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: #666;
}

.sp-card-add-detail p {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-right: 10px;
  font-weight: 700;
  color: #000;
}

.sp-card-add-detail span {
  padding-right: 10px;
  color: #000;
  font-weight: 700;
}

.sp-card-detail span {
  padding-right: 10px;
  font-weight: 700;
  color: #333;
}
.sp-card-comment {
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid #000;
  border-radius: 8px;
  padding: 5px;
  background-color: #fff;
  margin-top: 10px;
}

.sp-card-comment span:first-child {
  position: absolute;
  top: -11px;
  left: 16px;
  background-color: #fff;
  padding: 0 5px;
  font-weight: bold;
  color: #333;
}

.sp-card-comment .textarea {
  margin: 0;
  width: 100%;
  height: 40px;
  padding: 8px;
  color: #666;
  border: none;
  border-radius: 4px;
  background: none;
  resize: none;
  cursor: not-allowed;
  font-size: 14px;
  position: relative;
}

.sp-card-footer {
  width: 100%;
}

.connect-button {
  background-color: #06ad9d;
  color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 42px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-button img {
  padding-left: 10px;
}

.connect-button:hover {
  background-color: #05998a;
}
