.feed-edit-confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.feed-edit-confirmation-popup-content {
  background: white;
  padding: 70px;
  border-radius: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  animation: slideInDown 0.5s ease-out forwards;
}

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.feed-edit-contents {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.feed-edit-contents label {
  font-size: 20px;
  font-weight: 600;
  width: 40%;
  text-align: left;
  margin-right: 10px;
}

.feed-edit-contents span {
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
}

.feed-edit-contents input {
  font-size: 16px;
  width: 60%;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
}

.feed-edit-contents input:focus {
  outline: none;
  box-shadow: none;
}

.feed-edit-close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.feed-save-button {
  background-color: #4695b8;
  color: #fff;
  padding: .3em 4em;
  border: none;
  font-size: 20px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;
}
