.finance-previous-request {
  margin-top: 80px;
}

.finance-previous-tab-container {
  display: flex;
  margin-bottom: 40px;
  width: 98%;
}

.finance-previous-tab {
  cursor: pointer;
  flex: 1;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 400;
  border-bottom: 2px solid transparent;
}

.finance-previous-data-container {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.finance-cancelled{
    width: 140px;
}

.cancel-icon {
  margin-left: 5px;
  color: red;
}
