.tickets {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: flex-start;
    align-items: center;
    padding: 60px;
    z-index: 2;
  }
  
  .ticket-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 150px;
    background-color:#FFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .ticket-title {
    width: 150px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    margin-bottom: 10px;
  }
  
  .ticket-value {
    font-size: 24px;
    color: #000000;
    font-weight: 600;
  }
  