.nav-container {
  display: flex;
  height: 100%;
}

.nav-box {
  display: flex;
  flex-direction: column;
  background-color: #4695b8;
  padding-top: 10px;
  border-radius: 0px;
  width: 320px;
  margin-bottom: 0px;
}

.nav-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.logoimg {
  padding-left: 20px;
}

.nav-admin {
  padding: 4px 4px;
}

.admin-container {
  background-color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
}

.admin-content {
  font-size: 15px;
  line-height: 18px;
  padding-top: 4px;
  padding-left: 10px;
}

.admin-name {
  margin: 4px 0px;
  font-size: 20px;
  font-weight: 800;
}

.admin-type {
  margin: 8px 0px;
}

.admin-id {
  margin: 0px 0px;
}

.admin-setting {
  padding-left: 10px;
  text-align: right;
  font-size: 14px;
  color: #333;
}

.admin-setting p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.admin-setting img {
  padding-right: 5px;
}

.downarrow {
  padding-left: 5px;
}

.nav-body ul {
  list-style: none;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly ;
}

.nav-body ul li {
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}
.nav-ite {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding: 10px;
  font-size: 15px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: #176384;
  border: 1px solid #176384;
  border-radius: 4px;
  top: 260px;
  left: 33.5px;
  width: 226.5px;
  z-index: 1000;
}

ul.dropdown-menu  {
  list-style: none;
  padding: 0;
  height: auto;
}

.dropdown-menu li button {
  color: #fff;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #4695b8;
  border-radius: 4px;
  font-size: 15px;
  height: 50px;
  padding: 0;
  padding-left: 20px;
  cursor: pointer;
}

.dropdown-menu li:last-child button{
  border-bottom: none;
}

.dropdown-menu li button:hover,
.dropdown-menu li button.active-dropdown-item {
  background-color: #4695b8;
}

.active-dropdown-item {
  background-color: #4695b8;
}

.nav-ite p {
  margin-left: 15px;
  color: white;
  align-self: center;
}

.active {
  background-color: #176384;
}

.nav-ite img {
  width: 24px;
}

.nav-ite .arrow {
  padding-left: 5px;
  width: 12px;
  height: 7.4px;
}

.nav-body {
  width: 100%;
}

@media (max-width: 750px) {
  .nav-container {
    width: 150px;
  }
  .nav-box {
    width: 200px;
  }
}
.dropdown-container {
  display: flex;
  flex-direction: column;


  margin-top: 5px;
}

.dropdown-toggle {
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-toggle p {
  margin: 0;
  display: flex;
  align-items: center;
}
/* nav.css */
.dropdown-container {

  
  background-color: #176384;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

.dropdown-toggle {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  padding: 0;
  /* Remove default padding */
  margin: 0;
  /* Remove default margin */
  list-style-type: none;
  /* Remove bullet points */
}

.active-dropdown-item {
  /* background-color: #f0f0f0; */
  /* Background color for active item */
  font-weight: bold;
  /* Bold text for active item */
}
