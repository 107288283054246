.sp-id-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.sp-id-popup-content {
  background: white;
  border-radius: 30px;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 400px;
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  animation: slideInUp 0.5s ease-out forwards;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.sp-id-popup-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 4;
}

.sp-id-popup-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 90%;
  border-radius: 24px;
  background-color: #f4f4f4;
}

.sp-id-popup-header {
  padding: 10px 0;
  background-color: #dcecf9;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sp-id-popup-header .sp-id-popup-row {
  padding: 0;
}

.sp-id-popup-subheader {
  padding: 10px 0;
  width: auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sp-id-popup-row img {
  padding: 0 20px;
  font-weight: 600;
}

.sp-id-popup-row span {
  width: 10px;
  font-weight: 600;
}

.sp-id-popup-row {
  padding: 5px 0;
  display: flex;
  flex-direction: row;
}

.sp-id-popup-row p {
  width: 60px;
  font-weight: 600;
  margin: 0;
}
