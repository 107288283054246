.feed-management {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  z-index: 0;
}

.add-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.add-button span {
  padding-right: 10px;
  font-size: 25px;
}

.add-button button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background-color: #4695b8;
  border-radius: 6px;
  border: none;
  padding: .3em 2em;
}

.add-button button:hover {
  background-color: #357a9b;
}

.feed-tab-container{
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  width: 96%;
}

.feed-tabactive{
  border-bottom:3.5px solid #4695b8 ;
  border-radius: 6px;
  font-weight: 700;
}

.feed-tab{
  margin: 0 60px;
  width: 50%;
  text-align: center;
}

.feeds-card {
    margin:20px 10px ;
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    justify-content: flex-start;
    align-items: center;
    padding:40px 68px 20px 68px;
}

.feed-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  width: 261px;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card-content p {
  margin: 5px 0;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.card-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit-button {
  background-color: #4695b8;
  color: white;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 0px 0px 8px 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-button:hover {
  background-color: #357a9b;
}

.edit-icon {
  margin-left: 5px;
}