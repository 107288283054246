.feed-order-details-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.feed-order-details-content {
  background: white;
  border-radius: 30px;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 600px;
  max-width: 900px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  animation: slideInUp 0.5s ease-out forwards;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.feed-close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.formerid {
  font-size: 20px;
  font-weight: 700;
  margin-left: 20px;
  margin-block-end: 0em;
}

.feed-order-section {
  padding: 0 20px;
}

.feed-order-section h3 {
  margin-top: 10px;
}

.feed-order-card {
  background-color: #dcecf9;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 10px;
  width: 770px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.feed-order-date {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70px;
  width: 64px;
  border-radius: 5px;
}

.feed-order-date p {
  font-size: 16px;
  font-weight: 500;
  width: 50px;
}

.feed-order-info {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.feed-order-info strong {
  color: #7d7676;
  font-weight: 500;
}

.feed-order-info p {
  margin: 0;
  font-size: 16px;
}

.feed-products {
  width: 150px;
}

.feed-vlcc-location {
  width: 130px;
}

.feed-tot-price {
  width: 110px;
}

.feed-order-info span {
  display: block;
  margin-top: 5px;
  font-weight: 500;
}

.feed-order-scrollable {
  overflow-y: auto;
  max-height: 210px;
  padding-right: 10px;
}

.feed-order-scrollable::-webkit-scrollbar {
  width: 5px;
  background-color: #c8c7c7;
  border-radius: 20px;
}

.feed-order-scrollable::-webkit-scrollbar-thumb {
  background-color: #828181;
  border-radius: 20px;
}
