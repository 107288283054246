.individual-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.individual-popup-content {
  background: white;
  padding: 100px;
  border-radius: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  max-height: 580px;
  min-height: 300px;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  animation: slideInUp 0.5s ease-out forwards;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.individual-popup-contents {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.individual-popup-contents label {
  font-size: 20px;
  font-weight: 600;
  width: 40%;
  text-align: left;
  margin-right: 10px;
}

.individual-popup-contents span {
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
}

.individual-popup-contents input {
  font-size: 16px;
  width: 60%;
  height: 50px;
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
}

.individual-popup-content input:focus {
  outline: none;
  box-shadow: none;
}

.individual-popup-close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.individual-popup-add_button {
  background-color: #4695b8;
  color: #fff;
  width: 250px;
  height: 50px;
  border: none;
  margin-left: 200px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;
}
