.main-content {
  background-color: #dcecf9;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: fit-content;
  border-radius: 16px;
  margin-top: 40px;
  padding-bottom: 35px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.view-order-button {
  background-color: #4695b8;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5em 2em;
}

.view-order-button .arrow {
  margin-left: 10px;
  font-size: 100%;
}

.export-button {
  background-color: transparent;
  color: #000000;
  border: 3px solid #4695b8;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}
