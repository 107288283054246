.all-tickets {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.all-tickets-container {
  background-color: #dcecf9;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 10px auto;
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 1em;
  position: relative;
  width: 100%;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  width: 80%;
  position: relative;
  padding: 0 20px;
}

.progress-bar-step {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.progress-bar-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
  z-index: 2;
  font-size: 25px;
  font-weight: bold;
  color: black;
}

.progress-bar-active .progress-bar-circle {
  color: #000;
}

.progress-bar-label {
  padding-left: 10px;
  font-size: 20px;
  color: black;
  text-align: center;
  white-space: nowrap;
}

.progress-bar-active-label {
  font-weight: 700;
}

.progress-bar-indicator {
  position: absolute;
  top: 110%;
  height: 5px;
  background-color: #f2f2f2;
  z-index: 1;
  transform: translateY(-50%);
  border-radius: 2px;
  transition: left 0.5s ease, width 0.5s ease, transform 0.5s ease;
}

.progress-bar-line {
  position: absolute;
  top: 130%;
  left: 2%;
  width: 96%;
  height: 2px;
  border-radius: 100px;
  background-color: #000;
  z-index: 10;
  transform: translateY(-50%);
}

.all-tickets-section {
  margin-bottom: 30px;
}

.all-tickets-section h3 {
  margin-block-start: 0px;
}

.all-tickets-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.all-tickets-table th,
.all-tickets-table td {
  width: 200px;
  border: 1px solid #6d6c6c;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

.all-tickets-table th {
  background-color: #4695b8;
  color: #fff;
}

.all-tickets-table td {
  background-color: #dcecf9;
}

@media (max-width: 768px) {
  .all-tickets-container {
    width: 100%;
  }
}


