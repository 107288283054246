.finance-requirement {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.finance-view-previous-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.finance-view-previous-button button {
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
  background-color: #4695b8;
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  padding: .3em 2em;
}

.finance-view-previous-button span {
  padding-left: 10px;
}

.finance-view-previous-button button:hover {
  background-color: #3c83a1;
}

.finance-requirement-content {
  width: 95%;
}

.finance-tab-container {
  margin-top: 40px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.finance-tab {
  cursor: pointer;
  flex: 1;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 400;
}

.Loan {
  transform: translateX(0%);
}

.Insurance {
  transform: translateX(100%);
}

.tabactive {
  font-weight: 700;
  color: black;
  border-bottom: 4px solid #4695b8;
  border-radius: 8px;
}

.finance-data-container {
  margin-top: 20px;
  width: 100%;
}

.finance-data-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e5e5e5;
  width: 100%;
}

.finance-data-details {
  flex: 1;
  margin-right: 20px;
}

.finance-data-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.finance-data-name {
  font-size: 14px;
  font-weight: 700;
  width: 125px;
  margin-block-start: 0%;
  margin-block-end: 0%;
}

.finance-data-div span {
  font-size: 16px;
  font-weight: 700;
  width: 20px;
  margin-block-start: 0%;
  margin-block-end: 0%;
}

.finance-data-value {
  width: 250px;
  height: fit-content;
  margin-block-start: 0%;
  margin-block-end: 0%;
}

.finance-data-info-name {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 10px;
}

.finance-data-info {
  flex: 1;
  margin-right: 50px;
}

.finance-data-info textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  resize: none;
  outline: none;
}

.finance-data-actions {
  display: flex;
  flex-direction: column;
}

.finance-data-actions button {
  margin-bottom: 10px;
  width: 140px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.finance-connect-button {
  border: 2px solid #4695b8;
  background: none;
  color: #4695b8;
}

.finance-cancel-button {
  border: 2px solid #4695b8;
  background: none;
  color: #4695b8;
}

.finance-completed-button {
  border: none;
  background-color: #4695b8;
  color: white;
}
